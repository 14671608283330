@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400&display=swap&subset=cyrillic');

.fr-text-uppercase {
  text-transform: uppercase;
}

.nobr {
  white-space: nowrap;
}

h1 {
  font-size: 2em;
  line-height: 1em;
  margin: .4em 0;
}

h2 {
  font-size: 1.2em;
  line-height: 1.3em;
  margin: .5em 0;
}

h3 {
  font-size: 1.3em;
  line-height: 1.2em;
  margin: 0.6em 0;
}

h4 {
  font-size: 1em;
  line-height: 1em;
  margin: .67em 0;
}

* {
  box-sizing: border-box;
}

::before {
  box-sizing: border-box;
}

::after {
  box-sizing: border-box;
}

@media (min-width:2560px) {
  .body {
    min-width: 2543px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 17px;
    line-height: 20px;
    color: #000;
  }
}

@media (min-width:1920px) and (max-width:2559.98px) {
  .body {
    min-width: 1903px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 17px;
    line-height: 24px;
    color: #000;
  }
}

@media (min-width:1350px) and (max-width:1919.98px) {
  .body {
    min-width: 1332px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: #000;
  }
}

@media (min-width:960px) and (max-width:1349.98px) {
  .body {
    min-width: 960px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 17px;
    line-height: 25px;
    color: #000;
  }
}

@media (min-width:600px) and (max-width:959.98px) {
  .body {
    min-width: 613px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 21px;
    line-height: 30px;
    color: #000;
  }
}

@media (min-width:320px) and (max-width:599.98px) {
  .body {
    min-width: 355px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 14px;
    line-height: 19px;
    color: #000;
  }
}

@media (min-width:0) and (max-width:319.98px) {
  .body {
    min-width: 320px;
    margin: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 16px;
    line-height: 21px;
    color: #000;
  }
}




.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}
